<template>
    <v-container>

        <div class="titulo text-center">
            <br>
            <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
        letter-spacing: -0.02em; color: #FFFFFF;">SEGURO DE SALUD</label>
            <br>
            <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
        letter-spacing: -0.02em; color: #FFFFFF;">MASCOTAS LIBRE ELECCIÓN</label>
        </div>

        <div class="text-center">

            <h4 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #4F74E3; text-transform: uppercase;">¡GRACIAS POR CONFIAR <P style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #4F74E3; text-transform: uppercase;"> EN NOSOTROS!</P>
            </h4>

            <label class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2D387C; text-transform:uppercase;">{{ userData.name }}, haz contratado
                <P style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2D387C;"> exitosamente tu seguro para mascotas.</P>
            </label>

        </div>

        <v-row>

            <v-col>

                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto" height="250"
                    style="margin-top:-2%" max-width="400">
                    <v-card-text class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                    </v-card-text>

                    <v-col class="text-center">
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;">Recibirás tu póliza en un correo para que así la puedas
                            guardar, imprimir o consultar cuando quieras.</label>
                        <p></p>
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;">La enviaremos a {{ userData.email }}. Revisa tu bandeja
                            de
                            entrada, carpeta de SPAM y Promociones por si acaso.</label>
                    </v-col>
                </v-card>

                <br>

                <!-- <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 15px;
                    letter-spacing: -0.01em; color: #2D387C;">También ya puedes ver la poliza aquí:
                </h3>

                <br>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #2D387C; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0" @click="validate()">
                        <v-icon color="white" dark left>
                            mdi-file-multiple
                        </v-icon>
                        Ver la poliza
                    </v-btn>
                </div> -->

                <br>

                <h3 class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 15px;
                    letter-spacing: -0.01em; color: #4F74E3;">
                    ¿Quieres ver otros seguros?
                </h3>

                <br>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #4F74E3; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                        @click="returnPage()">
                        <v-icon color="white" dark left>
                            mdi-arrow-left
                        </v-icon>
                        Volver al home
                    </v-btn>
                </div>

                <br>

                <!-- <h3 class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                    color: #232323; opacity: 0.75;">
                    Si tienes problemas, no dudes en comunicarte <p style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                    color: #232323; opacity: 0.95;">con nosotros presionando aquí:</p>
                </h3>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #595959; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform: uppercase; color: white;" elevation="0"
                        @click="validate()">
                        <v-icon color="white" dark left>
                            mdi-headset
                        </v-icon>
                        ATENCIÓN AL CLIENTE
                    </v-btn>
                </div> -->

            </v-col>

        </v-row>

        <br>

    </v-container>
</template>

<script>
import ShieldService from "../services/shield.service";
import Titulo from "../assets/images/titulo.png"

import swal from 'sweetalert';
import CryptoJS from 'crypto-js'

export default {
    data() {
        return {
            secretKey: process.env.VUE_APP_CRYPT,
            success: null,
            id: null,
            load: false,
            Titulo,
            planData: null,
            userData: {
                name: null,
                email: null,
            },
            servicesData: null,
            activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
        }
    },
    methods: {
        returnPage() {
            window.location.href = process.env.VUE_APP_REDIRECTION
        }
    },
    async mounted() {
        const query = this.$route.query;
        if (query) {
            if (query.success === "true") {
                this.success = true;

                const userDataAux = sessionStorage.getItem('userData');
                const planDataAux = sessionStorage.getItem('planData');
                const servicesDataAux = sessionStorage.getItem('servicesData');

                if (userDataAux) {
                    const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
                    const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
                    this.userData = JSON.parse(decryptedDataUser);
                    console.log("userData: ", this.userData);
                }

                if (servicesDataAux) {
                    const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
                    const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
                    this.servicesData = JSON.parse(decryptedServicesData);
                    console.log("servicesData: ", this.servicesData);
                }

                if (planDataAux) {
                    const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
                    const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
                    this.planData = JSON.parse(decryptedDataPlan).plan;
                    console.log("planData: ", this.planData);
                }

                this.success = true;

                if (this.activeCampaingUse == 'TRUE') {

                    await ShieldService.updateContactActiveCampain(null, this.planData, null, this.servicesData, 3);
                }

                swal({
                    title: "¡Has contratado tu seguro con éxito! ",
                    text: "Revisa tu correo electrónico para obtener toda la información.",
                    icon: "success",
                    button: "Revisar detalle",
                });

                this.load = true

            } else if (query.success === "false") {
                this.success = false;
            }
        }
    },

}
</script>

<style>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}
</style>
